<template>
  <div class="outer-page">
    <x-table
      :no-data-text="CA('cat_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="450"
      @on-visible-change="modalChange"
    >
      <Form ref="form" :model="form" :label-width="100" :rules="rules">
        <FormItem label="分类名称" prop="name">
          <Input
            clearable
            v-model="form.name"
            placeholder="请输入分类名称"
          ></Input>
        </FormItem>
        <FormItem label="场景类型" prop="cg">
          <Select v-model="form.cg">
            <Option
              v-for="item in category"
              :value="item.num + '/' + item.name"
              :key="item.num"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="备注">
          <Input
            clearable
            v-model="form.remark"
            type="textarea"
            placeholder="请输入备注"
            :autosize="{ minRows: 2 }"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "分类名称",
            key: "name",
            minWidth: 200,
          },
          {
            title: "场景类型",
            minWidth: 100,
            key: "typeName",
          },
          {
            title: "备注",
            width: 260,
            key: "remark",
          },
          {
            title: "操作",
            width: 260,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("cat_update") && (
                    <a
                      style="margin-right:20px"
                      on-click={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("cat_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        title: "",
        show: false,
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        remark: "",
        cg: "",
      },
      rules: {
        name: [{ required: true, message: "请输入分类名称！" }],
        cg: [{ required: true, message: "请选择场景类型" }],
      },
      category: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增产品分类",
            ca: "",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "产品分类",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: '场景类型',
              component: 'select',
              field: 'type',
              data: this.category,
              defaultValue: '',
              parameterField: 'num',
              showField: 'name'
            }
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    getList() {
      if (!this.CA("cat_check")) return;
      this.table.loading = true;
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getCategory() {
      this.$post(this.$api.DICT.SYS_LIST, {
        name: "场景类型",
      }).then((res) => {
        this.category = res;
      });
    },
    search(value) {
      this.page.pageNo = 1
      this.search_data = value;
      this.getList();
    },
    add() {
      this.modal = {
        show: true,
        title: "新增分类",
        submitLoading: false,
      };
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.cg = row.type + "/" + row.typeName;
      this.modal = {
        show: true,
        title: "编辑分类",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.PRODUCT_CATEGORY.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    submit() {
      let params = { ...this.form };
      params.type = this.form.cg.split("/")[0];
      params.typeName = this.form.cg.split("/")[1];
      delete params.cg;
      let url;
      if (params.id) {
        url = this.$api.PRODUCT_CATEGORY.UPDATE;
      } else {
        url = this.$api.PRODUCT_CATEGORY.ADD;
      }
      this.modal.submitLoading = true;
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "修改成功！" : "增加成功！");
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },
    modalChange(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        remark: "",
        cg: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
    this.getCategory();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>